body {
  margin: 0;
  font-family: 'azo-sans-web', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #fff;
}

h1 {
  font-size: 3em;
  font-weight: 400;
}
h2 {
  font-size: 2.25em;
  font-weight: 400;
}
blockquote {
  font-style: italic;
  font-size: 1.1em;
  font-weight: 300;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  scroll-behavior: smooth;
}
